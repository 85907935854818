<template>
  <div id="app">
    <Deriv/>
  </div>
</template>

<script>
import { mobileRem } from "@/common/rem";
import Deriv from './components/Deriv.vue'
export default {
  name: 'App',
  components: {
    Deriv
  },
  created() {
    mobileRem();
  }
}
</script>

<style>
</style>
