<template>
<div class="deriv-layout">
    <div class="deriv-container">
        <a-row class="logo">
            <a-col>
                <img src="@/assets/images/deriv-logo.svg"/>
            </a-col>
        </a-row>
        <a-row class="deriv-main">
            <a-col class="deriv-main-img">
                <img class="deriv-main-img" src="@/assets/images/deriv-banner.svg"/>
            </a-col>
            <a-col class="deriv-main-col">
                <div class="deriv-main-h1">
                    <h1 class="font-bold">Crypto Derivatives<span></span></h1>
                    <p class="mg-t12">Reimagine financial derivatives in DeFi. Create the technology infrastructure to make it possible.</p>
                </div>
                <!--<div class="deriv-main-h2">
                    <h2>Our Work</h2>
                    <a target="_blank" href="https://cleardao.com">
                        <img src="@/assets/images/deriv-clear.svg"/>
                    </a>
                </div>-->
                <div class="deriv-main-btn">
                    <a-row :gutter="[30,0]" type="flex" justify="end">
                        <a-col>
                            <a target="_blank" href="https://twitter.com/derivstudio">
                                <img src="@/assets/images/deriv-fei.svg"/>
                            </a>
                        </a-col>
                        <a-col>
                            <a target="_blank" href="https://medium.com/@DerivStudio">
                                <img src="@/assets/images/deriv-tel.svg"/>
                            </a>
                        </a-col>
                        <a-col>
                            <a target="_blank" href="mailto:contact@derivstudio.com">
                                <img src="@/assets/images/deriv-email.svg"/>
                            </a>
                        </a-col>
                    </a-row>
                </div>
            </a-col>
        </a-row>
        <a-row class="deriv-foot">
            <p class="color-FFFFFF mg-t12">© 2024 DerivStudio</p>
        </a-row>
    </div>
</div>
</template>

<script>
export default {
  name: 'Deriv'
}
</script>
<style scoped lang="scss">
.deriv-layout{
    background: #000000;
    min-height: 100vh;
}
@media (min-width:821px) {
    .deriv-container{
        padding:40px 60px 0;
        .logo{
            img{
                width: 170px;
                height: 36px;
            }
        }
        .deriv-main{
            position: relative;
            display: flex;
            padding:100px 0 0 30px;
            .deriv-main-col{
                width:66%;
                padding-top: 100px;
                padding-bottom: 120px;
                padding-left: 6.66%;
            }
            .deriv-main-h1{
                h1{
                    font-size: 45px;
                    line-height: 60px;
                    color: #FFFFFF;
                    span{
                        width: 6px;
                        height: 6px;
                        background:#00C2F1;
                        display: inline-block;
                        margin-left: 4px;
                    }
                }
                p{
                    width: 420px;
                    font-size: 16px;
                    line-height: 22px;
                    color: #FFFFFF;
                    opacity: .7;
                }
            }
            .deriv-main-h2{
                padding-top: 88px;
                h2{
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #FFFFFF;
                    padding-bottom: 18px;
                }
                img{
                    width: 90px;
                    height: 23px;
                }
            }
            .deriv-main-btn{
                position: absolute;
                bottom: 40px;
                right: 0px;
                a{
                    width: 36px;
                    height: 36px;
                    border-radius: 18px;
                    border:1px solid #eeeeee;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .deriv-foot{
            padding-left: 30px;
        }
        @media (min-width:820px) and  (max-width:1200px){
            .deriv-main{
                .deriv-main-img{
                    img{
                        width: 100%;
                    }
                }
                .deriv-main-col{
                    width:60%;
                    padding-top: 40px;
                    padding-left: 6.66%;
                }
            }
        }
    }
}
@media (max-width:820px) {
    .deriv-container{
        max-width:750px;
        margin: 0 auto;
        padding:0.3rem 0.4rem 1rem;
        .logo{
            img{
                width: 170px;
                height: 36px;
            }
        }
        .deriv-main{
            padding:1.35rem 0 0 0.3rem;
            .deriv-main-col{
                top:-20px;
            }
            .deriv-main-img{
                width: 5.9rem;
                height: 6.84rem;
            }
            .deriv-main-h1{
                h1{
                    font-size: 0.9rem;
                    font-weight: bold;
                    line-height: 1rem;
                    color: #FFFFFF;
                    span{
                        width: 6px;
                        height: 6px;
                        background:#00C2F1;
                        display: inline-block;
                        margin-left: 4px;
                    }
                }
                p{
                    font-size: 0.2rem;
                    line-height:22px;
                    color: #FFFFFF;
                    opacity: .7;
                }
            }
            .deriv-main-h2{
                padding-top: 1rem;
                h2{
                    font-size: 0.2rem;
                    font-weight: 400;
                    line-height: 0.2rem;
                    color: #FFFFFF;
                    opacity: 1;
                }
                img{
                    margin-top: 20px;
                    width: 90px;
                    height: 23px;
                }
            }
            .deriv-main-btn{
                padding-top: 1.2rem;
                padding-right: 0.38rem;
                .ant-col{
                    margin-left: -3px;
                    margin-right: -3px;
                    a{
                        width: 36px;
                        height: 36px;
                        border-radius: 18px;
                        border:1px solid #eeeeee;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
        .deriv-foot{
            padding-left: 0.3rem;
        }
    }
}
</style>
